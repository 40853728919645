import { ChangeDetectorRef, Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FormGroup } from '@ng-stack/forms';
import { EroLevelProvider } from '../../../../shared/providers/ero-level-provider';
import { ExamTypeProvider } from '../../../../shared/providers/exam-type-provider';
import { ExamTypes } from '../../../../util/exam-types';
import { EroDto } from '../../../../../../generated/api';
import { ICONS } from '../../../fontawesome.module';

export interface EroDialogData {
  eroForm: FormGroup<EroDto>;
  eroPrice: number;
  eroInfoUrl?: string;
  examTypeId: string;
}

@Component({
  selector: 'sx-ero-dialog',
  template: `
    <form [formGroup]="form">
      <sx-content-card>
        <sx-content-card-section>
          <sx-content-card-title [icon]="iconTitle">
            {{ 'Exam Retake Option' | translate }}
          </sx-content-card-title>
          <sx-content-card-sub-title>
            {{ 'Exam Retake Option Description' | translate }}
          </sx-content-card-sub-title>
        </sx-content-card-section>
        <sx-content-card-section>
          <div class="checkbox-wrapper margin-top">
            <mat-checkbox
              formControlName="eroSelected"
              color="primary"
              (change)="updateRequiredField($event)"
            >
              {{ 'Exam Retake Option ' | translate }} ({{ eroPrice | currency: 'CHF' : 'CHF ' }})
            </mat-checkbox>
            <a *ngIf="eroInfoUrl" class="info-icon-button" [href]="eroInfoUrl" target="_blank">
              <mat-icon color="primary">info</mat-icon>
            </a>
          </div>
          <div *ngIf="isLinguaskillExam" class="split">
            <div class="split__cell">
              <mat-form-field>
                <mat-label>Target Level</mat-label>
                <mat-select formControlName="targetLevel" name="targetLevel" #levelSelection>
                  @for (level of levels; track level) {
                    <mat-option [value]="level.value">{{ level.viewValue }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <sx-validation-messages
                [control]="form.controls.targetLevel"
              ></sx-validation-messages>
            </div>
            <div class="split__cell"></div>
          </div>
        </sx-content-card-section>

        <sx-content-card-section>
          <div style="text-align: right">
            <button mat-flat-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
            <button mat-flat-button (click)="onOk()" color="primary">
              {{ 'Continue' | translate }}
            </button>
          </div>
        </sx-content-card-section>
      </sx-content-card>
    </form>
  `,
})
export class EroDialogComponent {
  @HostBinding('class')
  cssClass = 'dialog';

  @ViewChild('levelSelection') levelSelection: MatSelect;

  readonly form: FormGroup<EroDto>;
  readonly eroPrice: number;
  readonly eroInfoUrl: string;
  iconTitle = ICONS.faSync;
  readonly isLinguaskillExam;
  readonly levels = this.eroLevelProvider.getLevels();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: EroDialogData,
    private ref: MatDialogRef<EroDialogComponent>,
    private eroLevelProvider: EroLevelProvider,
    private examTypeProvider: ExamTypeProvider,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = data.eroForm;
    this.eroPrice = data.eroPrice;
    this.eroInfoUrl = data.eroInfoUrl;
    this.isLinguaskillExam =
      this.examTypeProvider.getExamTypeById(data.examTypeId) === ExamTypes.LINGUASKILL;
  }

  onOk() {
    this.ref.close(true);
  }

  onCancel() {
    this.ref.close(false);
  }

  updateRequiredField(event: MatCheckboxChange) {
    if (!event.checked) {
      this.levelSelection?.options.forEach((data: MatOption) => data.deselect());
    }
    this.form.controls.eroSelected.setValue(event.checked);
    this.form.controls.targetLevel.markAsTouched();
    this.form.controls.targetLevel.updateValueAndValidity();
  }
}
